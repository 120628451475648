:root {
  --primary-color: #0F2446;
  --text-color: #111111;

  --baumansFont: 'Baumans', cursive;
}

html {
  box-sizing: border-box;
  font-size: 10px;
}

*, *::before, *::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Play', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 700;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

section {
  padding: 5rem 0;
}

.section-title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 34px !important;
  line-height: 150% !important;
  color: #EEF0F2 !important;
  text-align: center !important;
  margin-bottom: 50px !important;
  display: block !important;
  max-width: 350px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.section-title.black {
  color: #0F2446 !important;
}

.btn {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
  padding: 1em 5em;
  border-radius: 8px;
  color: #FFFFFF;
  transition: all .3s ease;
}

.text-bold {
  font-weight: bold;
}

.btn-primary {
  background: #F47721;
}

.btn-primary:hover {
  color: #F47721;
  background: #ffffff;
}

.main {
  background-attachment: fixed !important;
}

/* Header CSS */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: #FFFFFF;
  opacity: 0.95;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
}

.header .container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header nav {
  display: flex;
  align-items: center;
}

.header__nav {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
}

.header__ham {
  display: none;
  cursor: pointer;
}

.header__nav li + li {
  margin-left: 4rem;
}

.header__nav li a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.header__nav li:last-child span {
  background: rgba(244, 119, 33, 0.05);
  border: 1px solid #F47721;
  box-sizing: border-box;
  border-radius: 8px;
  padding: .4rem 2.5rem;
}

.header__nav li a:hover {
  opacity: .65;
}

@media (max-width: 955px) {
  .header .header__ham {
    display: block;
  }

  .header nav {
    position: relative;
  }

  .header__nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 1rem;
    width: 20rem;
  }

  .header__nav.active {
    display: flex;
  }

  .header__nav li + li {
    margin-left: 0;
  }

  .header__nav li,
  .header__nav li a {
    width: 100%;
    text-align: center;
  }

  .header__nav li a {
    display: inline-block;
    padding: 1.2rem;
  }
}
@media (max-width: 624px) {
  .header {
    width: 100%;
    position: fixed !important;
  }

  .main {
    background-attachment: fixed !important;
    overflow-x: hidden;
  }
}

/* Hero CSS */
.hero {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  height: 55rem;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}

.hero .container {
  height: 100%;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero__title {
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 54px;
  color: #EEEEEE;
  text-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
}

.hero__subTitle {
  font-family: var(--baumansFont);
  font-style: normal;
  font-weight: normal;
  font-size: 52px;
  line-height: 65px;
  color: #ffffff;
  text-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  margin-bottom: 59px;
}

.hero button {
  align-self: baseline;
}

@media (max-width: 624px) {
  .hero {
    height: auto;
    margin-top: 86px;
  }

  .hero .container {
    align-items: center;
    text-align: center;
  }

  .hero button {
    align-self: center;
  }

  .hero h4 {
    font-size: 2.5rem;
  }
  
  .hero h3 {
    font-size: 4rem;
  }
}

/* Banner CSS */
.banner {
  /* background: rgba(229, 229, 229, .5); */
  background: #ffffff;
  text-align: center;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 2.5rem;
  line-height: 1.7;
  color: #000000;
  font-family: 'Red Hat Display', sans-serif;
}

@media (max-width: 624px) {
  .banner {
    font-size: 2rem;
  }
}

/* WhatWeDo CSS */

.whatWeDo {
  background: rgba(255, 255, 255, .7);
}

.whatWeDo h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  color: #0F2446;
  text-shadow: 0px 0px 16px rgba(15, 36, 70, 0.2);
  margin-bottom: 3.5rem;
}

.whatWeDo .whatWeDo__card {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  gap: 100px;
}

.whatWeDo .whatWeDo__card img {
  /* border-radius: 16px; */
  width: 100%;
}

.whatWeDo .whatWeDo__card .whatWeDo__img {
  flex: 800px 0 0;
}

.whatWeDo .whatWeDo__card:nth-child(2),
.whatWeDo .whatWeDo__card:nth-child(4) {
  flex-direction: row-reverse;
}

.whatWeDo .whatWeDo__card:nth-child(3) > div:last-child {
  text-align: right;
}

.whatWeDo .whatWeDo__card + .whatWeDo__card {
  margin-top: 5rem;
}

.whatWeDo .whatWeDo__card > div:first-child {
  width: 50rem;
  /* height: 20rem; */
  /* border-radius: 16px 0px 0px 16px; */
  overflow: hidden;
}

.whatWeDo .whatWeDo__card > div:first-child img {
  /* width: 100%;
  height: 100%; */
  object-fit: cover;
}

.whatWeDo .whatWeDo__card > div:last-child {
  padding: 1rem 2rem;
}

.whatWeDo .whatWeDo__card > div:last-child h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  color: #ffffff;
  font-family: "Play", sans-serif;
  margin-bottom: 1rem;
}

.whatWeDo .whatWeDo__card > div:last-child p {
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  color: #ffffff;
  line-height: 1.6;
}

@media (max-width: 624px) {
  .whatWeDo .whatWeDo__card {
    display: block;
  }
  .whatWeDo .whatWeDo__card > div:first-child {
    width: 100% !important;
    border-radius: 16px 16px 0px 0px !important;
    margin-bottom: 2rem;
  }
  .whatWeDo .whatWeDo__card {
    flex-direction: column !important;
  }
}

/* HowWeDoIt CSS */
.howWeDoIt {
  background: rgba(255, 255, 255, .75);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  padding-bottom: 5rem;
}

.howWeDoIt h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  color: #0F2446;
  text-shadow: 0px 0px 16px rgba(15, 36, 70, 0.2);
  margin-bottom: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.howWeDoIt .howWeDoIt__cards {
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(250px, 1fr) );
  grid-gap: 1.5rem;
}

.howWeDoIt__card {
  padding: 2rem;
  min-height: 28rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

.howWeDoIt__card > div {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  text-align: center;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}

.howWeDoIt__card > div > h4 {
  margin-bottom: 2rem;
}

.howWeDoIt__card img {
  margin-bottom: 2rem;
  aspect-ratio: 1 / 1;
  width: 120px;
}

.howWeDoIt__card > h4 {
  font-size: 2rem;
  color: #ffffff;
}

.howWeDoIt__card:hover > h4,
.howWeDoIt__card:hover > img {
  display: none;
}

.howWeDoIt__card:hover > div {
  transition: all .5s ease;
  opacity: 1;
  visibility: visible;
  display: flex;
  background: rgba(255, 255, 255, .9);
  transition: all .4s ease;
  border-radius: 8px;
  height: 100%;
}

@media (max-width: 624px) {
  .howWeDoIt h3 {
    flex-direction: column;
  }
  .howWeDoIt button {
    margin-top: 2rem;
  }

  .howWeDoIt__card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

/* Contact CSS */
.contact {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.contact h3 {
  font-family: "Play", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  color: #FFFFFF;
  text-shadow: 0px 0px 16px rgba(15, 36, 70, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.contact h6 {
  font-family: "Play", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2.8rem;
  color: #FFFFFF;
  margin-bottom: 6rem;
}

.contact .row {
  display: flex;
  margin-bottom: 2rem;
}

.contact label {
  cursor: pointer;
}

.contact .row > div {
  flex: 1;
}

.contact .row > label {
  display: block;
  width: 100%;
  font-family: "Play", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  color: #FFFFFF;
}

.contact .row label > span {
  display: block;
  margin-bottom: .4rem;
}

.contact .row > label > input,
.contact .row > label > textarea {
  background: #FAFAFA;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  outline: none;
  padding: 1.5rem;
}

.contact .checkboxes {
  background: #FAFAFA;
  color: #000000;
  padding: 2rem;
  display: flex;
  border-radius: 4px;
}

.contact .checkboxes > span {
  display: grid;
  grid-gap: 2rem 4rem;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  width: 100%;
}

.contact .checkboxes span + span {
  margin-left: 1.5rem;
}

.contact .checkboxes span label {
  display: block;
  padding: .5rem 0;
}

.contact .checkboxes span label > input {
  margin-right: 1rem;
}

.contact .confirm {
  color: #FFFFFF;
}

.contact .confirm > label {
  display: flex;
  align-items: center;
  padding: 1rem 0 2rem;
}

.contact .confirm > label > input {
  margin-right: 1rem;
}

@media (max-width: 624px) {
  .contact .checkboxes {
    flex-direction: column;
  }
  .contact .checkboxes span + span {
    margin-left: 0rem;
    margin-top: 1.5rem;
  }
}

/* Footer CSS */
.footer {
  background: #0F2446;
}

.footer .container > div:first-child {
  display: flex;
  align-items: center;
}

.footer .container > div:first-child span {
  display: inline-block;
  width: 100%;
  border-top: 2px solid #F47721;
  margin-left: 1.6rem;
}

.footer .footer__row {
  display: grid;
  grid-template-columns: 1fr 50rem;
}

.footer .footer__row > div:last-child {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer .footer__row > div:last-child p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: #FFFFFF;
  margin-top: 2rem;
}

.footer .footer__row > div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footer .footer__row > div:first-child h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  color: #FFFFFF;
  margin-top: 2rem;
  font-family: "Play", sans-serif;
}

.footer .footer__row > div:first-child h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  color: #F0F0F0;
  font-family: "Play", sans-serif;
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.footer .footer__row > div:first-child h5 img {
  margin-right: 1rem;
}

.footer .footer__row > div:first-child h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 2.4rem;
  color: #FFFFFF;
  font-family: "Play", sans-serif;
  margin-top: 2rem;
}

.footer .footer__row > div:first-child > div {
  display: flex;
  margin-top: 1rem;
}

.footer .footer__row > div:first-child > div > span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5rem;
}

.footer .footer__row > div:first-child > div > span > svg {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.footer .footer__row > div:first-child > div > span + span {
  margin-left: 2rem;
}

.footer .twitter:hover > svg > path {
  fill: #40AFFF;
}

.footer .instagram:hover > svg > path {
  fill: #FF029A;
}

.footer .facebook:hover > svg > path {
  fill: #0452FF;
}

.footer .youtube:hover > svg > path {
  fill: #E81B1B;
}

.footer .linkedin:hover > svg > path {
  fill: #4A80F9;
}

@media (max-width: 624px) {
  .footer .footer__row {
    display: flex;
    flex-direction: column;
  }
  .footer .footer__row > div:last-child {
    align-items: flex-start;
    margin-bottom: 3rem;
    order: -1;
  }
  .footer .container > div:first-child {
    display: none;
  }
}

/* WorkWithUs CSS */
.workWithUs {
  background: rgba(229, 229, 229, .5);
  padding-bottom: 0;
}

.workWithUs h3 {
  font-family: "Play", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  color: #FFFFFF;
  text-shadow: 0px 0px 16px rgba(15, 36, 70, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  color: #0F2446;
}

@media (max-width: 700px) {
  .workWithUs h3 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
  }
}

.workWithUs .workWithUs__cards {
  width: 100%;
  height: auto;
  margin-top: 5rem;
}

.workWithUs .workWithUs__card {
  width: 100%;
  height: 20rem;
  position: relative;
  overflow: hidden;
}

.workWithUs .workWithUs__card img {
  object-position: 50% 50%;
}

.workWithUs .workWithUs__card:hover > img {
  transform: scale(1.1);
}

.workWithUs .workWithUs__card + .workWithUs__card {
  margin-top: 1rem;
}

.workWithUs .workWithUs__card img {
  width: 100%;
  transition: all .3s ease;
}

.workWithUs .workWithUs__card h2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .2);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* OurApproach CSS */
.ourApproach {
  /* background: rgba(229, 229, 229, .7); */
  background: #ffffff;
}

.ourApproach img {
  width: 100%;
}

.ourApproach h3 {
  font-family: "Play", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  color: #FFFFFF;
  text-shadow: 0px 0px 16px rgba(15, 36, 70, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  color: #0F2446;
}

.ourApproach .ourApproach__cards {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.ourApproach .ourApproach__card {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 1rem 2rem;
  display: flex;
}

.ourApproach .ourApproach__card > div:first-child {
  color: rgba(15, 36, 70, 0.5);
  font-size: 5rem;
  font-weight: 600;
  width: 5rem;
}

.ourApproach .ourApproach__card > div:last-child {
  padding-left: 2rem;
}

.ourApproach .ourApproach__card > div:last-child h5 {
  font-family: "Play", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2.2rem;
  color: #0F2446;
  margin-bottom: .5rem;
}

.ourApproach .ourApproach__card > div:last-child p {
  font-family: "Play", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.4;
  color: #000000;
}

@media (max-width: 624px) {
  .ourApproach .ourApproach__cards {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* ToolsAndTech CSS */
.toolsAndTech {
  background: #ffffff;
  padding-top: 5rem !important;
}

.toolsAndTech h3 {
  font-family: "Play", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  color: #FFFFFF;
  text-shadow: 0px 0px 16px rgba(15, 36, 70, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  color: #0F2446;
}

.toolsAndTech .toolsAndTech__cards {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 2rem;
}

.toolsAndTech .toolsAndTech__card {
  background: #FFFFFF;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
}


.toolsAndTech .toolsAndTech__card img {
  width: 80%;
  height: 100%;
  object-fit: contain;
}

/* Admin CSS */
.admin__header {
  padding: .8rem 0;
}

.adminAuth {
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.adminAuth__wrapper {
  margin-top: -15rem;
}

.adminAuth__wrapper > h3 {
  color: #0F2446;
  text-shadow: 0px 0px 16px rgba(15, 36, 70, 0.2);
  margin-bottom: 1rem;
}

.adminAuth__form {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 1.5rem;
  min-width: 40rem;
  max-width: 100%;
}

.adminAuth__form .error {
  border-left: 3px solid #F47721;
  padding-left: 1rem;
  width: 40ch;
}

.adminAuth__form > div {
  width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
}

.adminAuth__form > div > label {
  display: block;
  font-family: "Red Hat Display", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  color: #303030;
}

.adminAuth__form > div > label > input {
  display: block;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 40px;
  margin-top: .4rem;
  padding: 0 1rem;
}

.adminTabs {
  width: 100%;
  height: auto;
}

.adminTabs__buttons {
  display: flex;
  align-items: center;
}

.adminTabs__buttons > button {
  background: rgba(244, 119, 33, 0.05);
  border: 1px solid #F47721;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Red Hat Display", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  color: #0F2446;
  padding: .4rem 1.4rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.adminTabs__buttons > button + button {
  margin-left: 1rem;
}

.adminTabs__buttons > button.active {
  background: #F47721;
  border: 1px solid #F47721;
  box-sizing: border-box;
  border-radius: 8px;
  color: #FFFFFF;
}

.adminDashboard {
  width: 100%;
  height: auto;
}

.adminDashboard__logout {
  display: flex;
  justify-content: flex-end;
}

.adminDashboard__logout > button {
  background: #F0F0F0;
  border-radius: 8px;
  padding: .8rem 2rem;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
}

.adminDashboard__logout > button:hover {
  background: #F47721;
  color: #ffffff;
  transition: all .3s ease;
}

.adminDashboard .container > h3 {
  font-family: "Red Hat Display", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  color: #0F2446;
  text-shadow: 0px 0px 16px rgba(15, 36, 70, 0.2);
  margin-bottom: 1.5rem;
}

.contactResponses {
  width: 100%;
  height: auto;
  margin-top: 1rem;
}

.contactResponses > h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  color: #0F2446;
  margin-bottom: 1.5rem;
}

.contactResponses__list {
  width: 100%;
  height: auto;
}

.contactResponses__item {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 1.5rem;
}

.contactResponses__item + div {
  margin-top: 2rem;
}

.contactResponses__itemHeader {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 2rem;
}

.contactResponses__itemHeader > div:last-child {
  text-align: right;
  font-family: "Red Hat Display", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  color: #606060;
}

.contactResponses__itemHeader > div:first-child > span {
  display: block;
}

.contactResponses__itemHeader > div:first-child > span > strong {
  width: 10%;
  display: inline-block;
}

.contactResponses__itemHeader > div:first-child > span a {
  margin-left: 1rem;
  background: #F0F0F0;
  border-radius: 4px;
  padding: .4rem .6rem;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #0F2446;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.contactResponses__itemHeader > div:first-child > span a:hover {
  color: #ffffff;
  background: #F47721;
  transition: all .3s ease;
}

.contactResponses__item > p {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: justify;
  color: #404040;
}

.contactResponses__item > p > strong {
  color: #303030;
}

.contactResponses__item > h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #303030;
  margin-bottom: 1rem;
}

.contactResponses__itemChips {
  display: flex;
}

.contactResponses__itemChips > span {
  background: #F0F0F0;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 32px;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
}

.contactResponses__itemChips > span + span {
  margin-left: 1rem;
}

.contactResponses__item > h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #404040;
}

.contactResponses__item > h4 > strong {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #303030;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.contactList {
  width: 100%;
  height: auto;
  margin-top: 1rem;
}

.contactList > h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  color: #0F2446;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contactList > h3 > span {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contactList > h3 > span > select,
.contactList > h3 > span > button {
  border: 1px solid #F47721;
  background: rgba(244, 119, 33, 0.05);
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Red Hat Display", sans-serif;
  padding: .4rem 1.4rem;
  outline: none;
  cursor: pointer;
  font-size: 1.4rem;
}

.contactList > h3 > span > button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
}

.contactList .table {
  border-radius: 8px;
}

.contactList table {
  width: 100%;
  border-collapse: collapse;
}

.contactList table th,
.contactList table td {
  text-align: left;
  padding: 1rem;
  border: 1px solid #CCCCCC;
}

.contactList table td > span {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.contactList table td > span button {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown {
  position: relative;
}

.dropdown__options {
  position: absolute;
  top: 100%;
  right: 0;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-width: 20rem;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;
}

.dropdown__options.active {
  opacity: 1;
  visibility: visible;
}

.dropdown__options > span {
  display: block;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
}

.dropdown__options > span:hover {
  background: #ccc;
  transition: all .3s ease;
}

.contactResponses__item textarea {
  width: 100%;
  min-height: 10rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 2rem;
  outline: none;
  font-family: "Red Hat Display", sans-serif;
  padding: 1rem;
  margin-bottom: 1rem;
}

.toolsAndTech .owl-stage {
  display: flex;
  align-items: center; 
}

.toolsAndTech .item {
  filter: grayscale(100%);
  transition: all 250ms ease-in-out;
}

.toolsAndTech .item:hover {
  filter: none;
}

#toolsAndTech {
  display: flex;
  align-items: center;
  padding: 0 40px;
  margin-right: -20px;
  margin-left: -20px;
  width: 5000px;
  overflow: visible;
  height: auto;
}